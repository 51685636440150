body {
    margin: 0;
}

#instruction-block-div {
    font-family: Roboto, serif;
}

#instruction-block-div > .ql-align-center {
    text-align: center;
}

#instruction-block-div > .ql-align-right {
    text-align: right;
}

#instruction-block-div > .ql-align-justify {
    text-align: justify;
}
#instruction-block-div img {
    max-width: 100%;
}

input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    border: none;
}

input[type="color"]::-webkit-color-swatch {
    border: none;
}
